<template>
    <div class="login_main">
        <div class="login_panel">
            관리자 세션이 종료되였습니다. 재로그인후 사용해주세요
        </div>
    </div>
</template>

<script>
    export default {
        name: "ManagerSessionOver",
        data() {
            return {

            }
        }
        ,
        methods: {

        }
    }
</script>

<style scoped>
    @import url("../../assets/css/adminsitrator.css");

    .login_main {
        width: 100%;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        font: 100% / 1.5 Raleway, sans-serif;
        color: hsl(230, 100%, 95%);
        background: linear-gradient(135deg, hsl(230, 40%, 12%), hsl(230, 20%, 7%)) !important;
    }

    .login_panel {
        width: 570px;
        height: 200px;
    }



</style>